/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Flex } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";
import { useHttp } from "../../../contexts/Http";
import LacLoading from "../LacLoading";
import ListItems from "../ListItems";
import TodaysSchedule from "./TodaysSchedule";

const VirtualTour = () => {
  const { authData, renderVirtualTour, setRenderVirtualTour } = useAuth();
  const { post, getData, setHomePageRefreshed } = useHttp();
  const [vtours, setVTours] = useState([]);
  const [load, setLoad] = useState(true);

  const getNewCalls = async () => {
    setLoad(true);
    const body = {
      query: `{
          VirtualTourList(virtualtourArgs: {status: ["attended","waiting"]}){
            created_at
            customer_name
            customer_email
            disconnected_on
            email
            telephone
            host_token
            guest_token
            joined_on
            recorded_session_link
            room_id
            salesrep_id
            join_rejoin_ids
            since
            status
            updated_at
            virtualtour_id
            salesrep_name
            room_links {
              designer
              guest
              host
              salesrep
            }
          }
        }`,
      variables: {},
    };

    const res = await post(body);
    const data = getData(res);
    if (data) {
      const { VirtualTourList } = data;
      setVTours(VirtualTourList);
      setHomePageRefreshed(i => i + 1);
    }
    setLoad(false);
  };

  useEffect(() => {
    if (renderVirtualTour) {
      getNewCalls();
      setRenderVirtualTour(0);
    }
  }, [renderVirtualTour]);

  useEffect(() => {
    if (authData) {
      getNewCalls();
    }
  }, [authData]);

  if (load) {
    return <LacLoading />;
  }
  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      <Flex
        css={{
          width: "100%",
          minHeight: "50%",
          borderBottom: "1px solid $border_default",
        }}
        direction="column"
      >
        <ListItems
          items={vtours}
          emptyMessage="No virtual tours found."
          join={true}
          page="lilyannlive"
        />
      </Flex>
      <TodaysSchedule />
    </Flex>
  );
};
export default VirtualTour;
