import { Flex } from "@100mslive/roomkit-react";
import { useAuth } from "../../contexts/Auth";
import FullPageProgress from "../FullPageProgress";
import LacHeader from "./LacHeader";
import "./LacCommon.css";

const MainContainer = ({ children, header = false, pageTitle = "" }) => {
  const auth = useAuth();

  if (auth.loading) {
    return <FullPageProgress />;
  }

  return (
    <Flex css={{ width: "100%" }} direction="column">
      {header === true && <LacHeader {...{ pageTitle }} />}
      <Flex
        css={{
          width: "100%",
          backgroundColor: "$on_primary_high",
        }}
        justify="center"
        align={header ? "start" : "center"}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default MainContainer;
