const signIn = object => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        token: object.token,
        email: object.email,
        name: object.name,
        user_id: object.user_id,
        created_at: object.created_at,
        is_admin: object.is_admin,
        status: object.status,
      });
    }, 1000);
  });
};

export const authService = {
  signIn,
};
