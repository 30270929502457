import {
  ActivityIcon,
  AddCircleIcon,
  AddIcon,
  AddSolidIcon,
  AlarmIcon,
  AlertOctagonIcon,
  AlertRoundIcon,
  AlertTriangleIcon,
  AlertTriangleSolidIcon,
  AllAppsIcon,
  AlternativeHeadphonesIcon,
  AndroidIcon,
  AnnouncementIcon,
  AppleIcon,
  ArchiveIcon,
  ArrowLeftRightIcon,
  ArrowRightIcon,
  ArrowTopDownIcon,
  AudioLevelIcon,
  AudioPlayerIcon,
  AwardIcon,
  BackDeleteIcon,
  BackwardIcon,
  BagIcon,
  BarIcon,
  BatteryFullIcon,
  BatteryPowerIcon,
  BillIcon,
  BluetoothIcon,
  BlurPersonHighIcon,
  BlurPersonLowIcon,
  BoltIcon,
  BookIcon,
  BookmarkIcon,
  BotIcon,
  BoxIcon,
  BranchIcon,
  BrbIcon,
  BrbTileIcon,
  BrushDesignIcon,
  BugIcon,
  BuildingIcon,
  CalculatorIcon,
  CalculatorIconsIcon,
  CalendarIcon,
  CameraFlipIcon,
  CardIcon,
  CardWithCvcIcon,
  CartIcon,
  ChangeRoleIcon,
  ChatIcon,
  ChatUnreadIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipIcon,
  ClockIcon,
  ClosedCaptionIcon,
  CloseIcon,
  CloudUploadIcon,
  CodeIcon,
  ColoredHandIcon,
  ColoredTimeIcon,
  ComputerIcon,
  ConnectivityIcon,
  CookieIcon,
  CopyIcon,
  CropIcon,
  CrossCircleIcon,
  CrossIcon,
  DesktopIcon,
  DiscordColoredIcon,
  DiscordIcon,
  DividerIcon,
  DoorIcon,
  DownloadIcon,
  DragHandleIcon,
  EditIcon,
  EllipsisIcon,
  EmojiIcon,
  EndStreamIcon,
  ExitIcon,
  ExpandIcon,
  ExternalLinkIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  FacebookIcon,
  FigmaIcon,
  FileIcon,
  FilesIcon,
  FilterIcon,
  FilterOneIcon,
  FitnessIcon,
  FitScreenIcon,
  FlagIcon,
  FlutterIcon,
  FlutterWIthColourIcon,
  FolderIcon,
  FolderOpenIcon,
  ForwardIcon,
  ForwardSolidIcon,
  GameIcon,
  GiftIcon,
  GitForkIcon,
  GithubIcon,
  GlobeIcon,
  GlobeTwoIcon,
  GoLiveIcon,
  GoogleIcon,
  GraduationIcon,
  GridFourIcon,
  GridIcon,
  GridSixIcon,
  GuageIcon,
  HamburgerMenuIcon,
  HandIcon,
  HandRaiseFilledIcon,
  HandRaiseIcon,
  HandRaiseSlashedIcon,
  HangUpIcon,
  HeadphonesIcon,
  HeartIcon,
  HerokuIcon,
  HomeIcon,
  HorizontalMenuIcon,
  InfoIcon,
  InformationIcon,
  InformationSolidIcon,
  InteractionClosedIcon,
  InteractionOpenIcon,
  InviteIcon,
  InviteStageIcon,
  JavascriptIcon,
  JoinIcon,
  LanguagesIcon,
  LayersIcon,
  LayoutIcon,
  LegoIcon,
  LinkedInIcon,
  LinkIcon,
  LinkTwoIcon,
  ListCheckIcon,
  ListReportIcon,
  MailIcon,
  MapPinIcon,
  MaximizeIcon,
  MicIcon,
  MicOffIcon,
  MicOnIcon,
  MinimizeIcon,
  MinusIcon,
  MobileIcon,
  MoneyIcon,
  MouseClickIcon,
  MouseIcon,
  MoveIcon,
  MusicIcon,
  NetworkIcon,
  NextIcon,
  NextJsIcon,
  NightIcon,
  NoEntryIcon,
  NotificationsIcon,
  OfferIcon,
  OpenBookIcon,
  OpenCaptionIcon,
  PadLockOnIcon,
  PaletteIcon,
  PauseCircleIcon,
  PauseIcon,
  PauseSolidIcon,
  PencilDrawIcon,
  PencilIcon,
  PenIcon,
  PeopleAddIcon,
  PeopleIcon,
  PeopleRemoveIcon,
  PersonContactIcon,
  PersonIcon,
  PersonRectangleIcon,
  PhoneIcon,
  PhotoIcon,
  PinIcon,
  PipIcon,
  PlayCircleIcon,
  PlayIcon,
  PlaylistIcon,
  PlaySolidIcon,
  PoorConnectivityIcon,
  PoorConnectivitySolidIcon,
  PrevIcon,
  QrCodeIcon,
  QuestionIcon,
  QuestionMarkIcon,
  QuizActiveIcon,
  QuizIcon,
  RadioIcon,
  ReactIcon,
  RecordIcon,
  RecordOffIcon,
  RefreshIcon,
  RejectIcon,
  RemoveUserIcon,
  RepeatIcon,
  ReplyIcon,
  ReportIcon,
  RestApiIcon,
  RocketIcon,
  SaveIcon,
  SearchIcon,
  SendIcon,
  ServerIcon,
  SettingsIcon,
  ShareIcon,
  ShareScreenIcon,
  ShieldAlertIcon,
  ShieldIcon,
  ShrinkIcon,
  ShuffleIcon,
  SlackIcon,
  SolidCheckCircleIcon,
  SparkleIcon,
  SpeakerIcon,
  SpotlightIcon,
  SquareMenuIcon,
  StarIcon,
  StatsIcon,
  StopIcon,
  SunIcon,
  SunWithFaceIcon,
  SupportIcon,
  Svg100MsLogoIcon,
  TabletIcon,
  TagIcon,
  TargetIcon,
  TerminalIcon,
  TextboxIcon,
  TextFormatIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TranscriptIcon,
  TrashIcon,
  TrophyFilledIcon,
  TwitterIcon,
  UnpinIcon,
  UploadIcon,
  VerifiedIcon,
  VerticalMenuIcon,
  VideoConferencingIcon,
  VideoOffIcon,
  VideoOnIcon,
  VideoPlayerIcon,
  ViewIcon,
  VirtualBackgroundIcon,
  VolumeOneIcon,
  VolumeTwoIcon,
  VolumeZeroIcon,
  WalletIcon,
  WebhookIcon,
  WiredMicIcon,
  WrenchIcon,
  YogaIcon,
  YoutubeIcon,
  ZapIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "@100mslive/react-icons";

const Test = () => {
  return (
    <>
      <Svg100MsLogoIcon /> <ActivityIcon /> <AddCircleIcon /> <AddIcon />{" "}
      <AddSolidIcon /> <AlarmIcon /> <AlertOctagonIcon /> <AlertRoundIcon />{" "}
      <AlertTriangleIcon /> <AlertTriangleSolidIcon /> <AllAppsIcon />{" "}
      <AlternativeHeadphonesIcon /> <AndroidIcon /> <AnnouncementIcon />{" "}
      <AppleIcon /> <ArchiveIcon /> <ArrowLeftRightIcon /> <ArrowRightIcon />{" "}
      <ArrowTopDownIcon /> <AudioLevelIcon /> <AudioPlayerIcon /> <AwardIcon />{" "}
      <BrbIcon /> <BrbTileIcon /> <BackDeleteIcon /> <BackwardIcon />{" "}
      <BagIcon /> <BarIcon /> <BatteryFullIcon /> <BatteryPowerIcon />{" "}
      <BillIcon /> <BluetoothIcon /> <BlurPersonHighIcon />{" "}
      <BlurPersonLowIcon /> <BoltIcon /> <BookIcon /> <BookmarkIcon />{" "}
      <BotIcon /> <BoxIcon /> <BranchIcon /> <BrushDesignIcon /> <BugIcon />{" "}
      <BuildingIcon /> <CalculatorIcon /> <CalculatorIconsIcon />{" "}
      <CalendarIcon /> <CameraFlipIcon /> <CardIcon /> <CardWithCvcIcon />{" "}
      <CartIcon /> <ChangeRoleIcon /> <ChatIcon /> <ChatUnreadIcon />{" "}
      <CheckCircleIcon /> <CheckIcon /> <ChevronDownIcon /> <ChevronLeftIcon />{" "}
      <ChevronRightIcon /> <ChevronUpIcon /> <ClipIcon /> <ClockIcon />{" "}
      <CloseIcon /> <ClosedCaptionIcon /> <CloudUploadIcon /> <CodeIcon />{" "}
      <ColoredHandIcon /> <ColoredTimeIcon /> <ComputerIcon />{" "}
      <ConnectivityIcon /> <CookieIcon /> <CopyIcon /> <CropIcon />{" "}
      <CrossCircleIcon /> <CrossIcon /> <DesktopIcon /> <DiscordColoredIcon />{" "}
      <DiscordIcon /> <DividerIcon /> <DoorIcon /> <DownloadIcon />{" "}
      <DragHandleIcon /> <EditIcon /> <EllipsisIcon /> <EmojiIcon />{" "}
      <EndStreamIcon /> <ExitIcon /> <ExpandIcon /> <ExternalLinkIcon />{" "}
      <EyeCloseIcon /> <EyeOpenIcon /> <FacebookIcon /> <FigmaIcon />{" "}
      <FileIcon /> <FilesIcon /> <FilterIcon /> <FilterOneIcon />{" "}
      <FitScreenIcon /> <FitnessIcon /> <FlagIcon /> <FlutterIcon />{" "}
      <FlutterWIthColourIcon /> <FolderIcon /> <FolderOpenIcon />{" "}
      <ForwardIcon /> <ForwardSolidIcon /> <GameIcon /> <GiftIcon />{" "}
      <GitForkIcon /> <GithubIcon /> <GlobeIcon /> <GlobeTwoIcon />{" "}
      <GoLiveIcon /> <GoogleIcon /> <GraduationIcon /> <GridFourIcon />{" "}
      <GridIcon /> <GridSixIcon /> <GuageIcon /> <HamburgerMenuIcon />{" "}
      <HandIcon /> <HandRaiseFilledIcon /> <HandRaiseIcon />{" "}
      <HandRaiseSlashedIcon /> <HangUpIcon /> <HeadphonesIcon /> <HeartIcon />{" "}
      <HerokuIcon /> <HomeIcon /> <HorizontalMenuIcon /> <InfoIcon />{" "}
      <InformationIcon /> <InformationSolidIcon /> <InteractionClosedIcon />{" "}
      <InteractionOpenIcon /> <InviteIcon /> <InviteStageIcon />{" "}
      <JavascriptIcon /> <JoinIcon /> <LanguagesIcon /> <LayersIcon />{" "}
      <LayoutIcon /> <LegoIcon /> <LinkIcon /> <LinkTwoIcon /> <LinkedInIcon />{" "}
      <ListCheckIcon /> <ListReportIcon /> <MailIcon /> <MapPinIcon />{" "}
      <MaximizeIcon /> <MicIcon /> <MicOffIcon /> <MicOnIcon /> <MinimizeIcon />{" "}
      <MinusIcon /> <MobileIcon /> <MoneyIcon /> <MouseClickIcon />{" "}
      <MouseIcon /> <MoveIcon /> <MusicIcon /> <NetworkIcon /> <NextIcon />{" "}
      <NextJsIcon /> <NightIcon /> <NoEntryIcon /> <NotificationsIcon />{" "}
      <OfferIcon /> <OpenBookIcon /> <OpenCaptionIcon /> <PipIcon />{" "}
      <PadLockOnIcon /> <PaletteIcon /> <PauseCircleIcon /> <PauseIcon />{" "}
      <PauseSolidIcon /> <PenIcon /> <PencilDrawIcon /> <PencilIcon />{" "}
      <PeopleAddIcon /> <PeopleIcon /> <PeopleRemoveIcon />{" "}
      <PersonContactIcon /> <PersonIcon /> <PersonRectangleIcon /> <PhoneIcon />{" "}
      <PhotoIcon /> <PinIcon /> <PlayCircleIcon /> <PlayIcon />{" "}
      <PlaySolidIcon /> <PlaylistIcon /> <PoorConnectivityIcon />{" "}
      <PoorConnectivitySolidIcon /> <PrevIcon /> <QrCodeIcon /> <QuestionIcon />{" "}
      <QuestionMarkIcon /> <QuizActiveIcon /> <QuizIcon /> <RadioIcon />{" "}
      <ReactIcon /> <RecordIcon /> <RecordOffIcon /> <RefreshIcon />{" "}
      <RejectIcon /> <RemoveUserIcon /> <RepeatIcon /> <ReplyIcon />{" "}
      <ReportIcon /> <RestApiIcon /> <RocketIcon /> <SaveIcon /> <SearchIcon />{" "}
      <SendIcon /> <ServerIcon /> <SettingsIcon /> <ShareIcon />{" "}
      <ShareScreenIcon /> <ShieldAlertIcon /> <ShieldIcon /> <ShrinkIcon />{" "}
      <ShuffleIcon /> <SlackIcon /> <SolidCheckCircleIcon /> <SparkleIcon />{" "}
      <SpeakerIcon /> <SpotlightIcon /> <SquareMenuIcon /> <StarIcon />{" "}
      <StatsIcon /> <StopIcon /> <SunIcon /> <SunWithFaceIcon /> <SupportIcon />{" "}
      <TabletIcon /> <TagIcon /> <TargetIcon /> <TerminalIcon />{" "}
      <TextFormatIcon /> <TextboxIcon /> <ThumbsDownIcon /> <ThumbsUpIcon />{" "}
      <TranscriptIcon /> <TrashIcon /> <TrophyFilledIcon /> <TwitterIcon />{" "}
      <UnpinIcon /> <UploadIcon /> <VerifiedIcon /> <VerticalMenuIcon />{" "}
      <VideoConferencingIcon /> <VideoOffIcon /> <VideoOnIcon />{" "}
      <VideoPlayerIcon /> <ViewIcon /> <VirtualBackgroundIcon />{" "}
      <VolumeOneIcon /> <VolumeTwoIcon /> <VolumeZeroIcon /> <WalletIcon />{" "}
      <WebhookIcon /> <WiredMicIcon /> <WrenchIcon /> <YogaIcon />{" "}
      <YoutubeIcon /> <ZapIcon /> <ZoomInIcon /> <ZoomOutIcon />
    </>
  );
};

export default Test;
