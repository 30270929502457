// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// const analytics = getAnalytics(app);

export const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
    });
    return token;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const deleteFCMToken = async () => {
  try {
    return await deleteToken(messaging);
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const requestForToken = () => {
  console.log("Requesting permission...");
  if (typeof Notification !== "undefined") {
    Notification.requestPermission().then(async permission => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        const token = await getFCMToken();
        console.log("token", token);
      }
    });
  }
};

export const onMessageListener = () => {
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      console.log("Message received. ", payload);
      resolve(payload);
    });
  });
};

export default app;
