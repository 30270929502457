import { useCallback, useEffect, useState } from "react";
import { Flex } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";
import { useHttp } from "../../../contexts/Http";
import LacLoading from "../LacLoading";
import ListItems from "../ListItems";

const Schedule = () => {
  const { authData, renderSchedule, setRenderSchedule } = useAuth();
  const { post, getData } = useHttp();
  const [vTourSchedules, setVTourSchedules] = useState([]);
  const [load, setLoad] = useState(false);

  const getScheduleCalls = useCallback(async () => {
    setLoad(true);
    const body = {
      query: `{
        VirtualTourScheduleList(virtualtourScheduleArgs: { salesrep_id: true }) {
          address
          appointment
          appointment_datetime
          current_time
          created_at
          email
          fullname
          is_reminder_email_sent
          phone
          room_id
          salesrep_id
          salesrep_name
          schedule_id
          support_type
          updated_at
          virtualtour_id
        }
      }`,
      variables: {},
    };

    const res = await post(body);
    const data = getData(res);
    if (data) {
      const { VirtualTourScheduleList } = data;
      setVTourSchedules(VirtualTourScheduleList);
    }
    setLoad(false);
  }, [post, getData]);

  useEffect(() => {
    if (renderSchedule) {
      getScheduleCalls();
      setRenderSchedule(0);
    }
  }, [renderSchedule, setRenderSchedule, getScheduleCalls]);

  useEffect(() => {
    if (authData) {
      getScheduleCalls();
    }
  }, [authData, getScheduleCalls]);

  if (load) {
    return <LacLoading />;
  }

  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      <ListItems
        items={vTourSchedules}
        emptyMessage="No calls in Schedule list."
        join={false}
        page="schedule"
      />
    </Flex>
  );
};
export default Schedule;
