import { useEffect } from "react";
import {
  ClockIcon,
  HangUpIcon,
  LayersIcon,
  RefreshIcon,
} from "@100mslive/react-icons";
import { Flex } from "@100mslive/roomkit-react";
import LacTabs from "../../components/LacCommon/LacTabs";
import MainContainer from "../../components/LacCommon/MainContainer";
import History from "../../components/LacCommon/TabContent/History";
import Missed from "../../components/LacCommon/TabContent/Missed";
import Schedule from "../../components/LacCommon/TabContent/Schedule";
import VirtualTour from "../../components/LacCommon/TabContent/VirtualTour";
import { useAuth } from "../../contexts/Auth";
import { useHttp } from "../../contexts/Http";

const Home = () => {
  const { authData } = useAuth();
  const { post, getData } = useHttp();

  useEffect(() => {
    let intervalTimer;
    if (authData) {
      const updateStatusInterval = async () => {
        const body = {
          query: `mutation {
          ActivityStatus(user_id: "${authData.user_id}") {
            updated_at
            user_id
          }
        }`,
          variables: {},
        };

        const res = await post(body);
        const data = getData(res);
        if (data) {
          //console.log(data);
        }
      };
      intervalTimer = setInterval(() => {
        updateStatusInterval();
      }, 60000);
      console.log("setInterval", intervalTimer);
    }
    return () => {
      console.log("clearInterval");
      clearInterval(intervalTimer);
    };
  }, [post, getData, authData]);

  const tabData = [
    {
      key: "lilyannlive",
      label: "Lily Ann LIVE",
      icon: <RefreshIcon />,
      content: <VirtualTour />,
    },
    {
      key: "schedule",
      label: "Schedule",
      icon: <ClockIcon />,
      content: <Schedule />,
    },
    {
      key: "history",
      label: "History",
      icon: <LayersIcon />,
      content: <History />,
    },
    {
      key: "missed",
      label: "Missed",
      icon: <HangUpIcon />,
      content: <Missed />,
    },
  ];

  return (
    <MainContainer header={true}>
      <Flex
        css={{
          width: "100%",
          maxWidth: 1320,
          padding: 20,
          backgroundColor: "$background_dim",
          minHeight: "calc(100vh - 60px)",
        }}
        justify="center"
      >
        <LacTabs tabs={tabData} />
        <Flex css={{ width: "min-content", minHeight: 60 }} />
      </Flex>
    </MainContainer>
  );
};

export default Home;
