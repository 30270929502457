import { useMemo, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useMedia } from "react-use";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  CrossCircleIcon,
  VerticalMenuIcon,
} from "@100mslive/react-icons";
import {
  Box,
  config as cssConfig,
  Dropdown,
  Flex,
  IconButton,
  Text,
} from "@100mslive/roomkit-react";
import { Image } from "../../components/Image";
import { useAuth } from "../../contexts/Auth";
import { useHttp } from "../../contexts/Http";
import { deleteFCMToken } from "../../services/firebaseService";
import "./LacCommon.css";

const pageTitles = {
  lilyannlive: "Lily Ann LIVE",
  schedule: "Schedule Calls",
  history: "Calls History",
  missed: "Missed Calls",
};

const statusIcons = {
  available: <CheckCircleIcon css={{ color: "$alert_success" }} />,
  away: <CrossCircleIcon css={{ color: "$alert_warning" }} />,
};

const LacHeader = ({ pageTitle: pageTitleProps = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMedia(cssConfig.media.md);
  const [searchParams] = useSearchParams();
  const { authData, signOut, updateAuth } = useAuth();
  const { post, getData } = useHttp();
  const [open, setOpen] = useState(false);
  const [available, setAvailable] = useState(false);

  const pageKey = useMemo(() => {
    return searchParams.get("tab");
  }, [searchParams]);

  const logout = async () => {
    let body = {
      query: `
        mutation {
          logoutSalesrep(token: "${authData?.token}") {
            created_at
            message
          }
        }
      `,
      variables: {},
    };

    post(body)
      .then(async res => {
        await deleteFCMToken();
        if (res.data.errors == null) {
          signOut();
        } else {
          if (res.data.errors[0].code === 40001) {
            signOut();
          } else {
            console.log(res.data.errors[0].message);
          }
        }
      })
      .catch(function (error) {});
  };

  const statusHandler = async status => {
    const newAuth = { ...authData, status };
    await updateAuth(newAuth);
    const body = {
      query: `mutation {
        UpdateSalesrepStatus(status: "${status}") {
          created_at
          email
          is_admin
          name
          status
          token
          user_id
        }
      }`,
      variables: {},
    };
    post(body)
      .then(async res => {
        const data = getData(res);
        const { UpdateSalesrepStatus = {} } = data;
        const { status: newUpdatedStatus } = UpdateSalesrepStatus;
        if (newUpdatedStatus) {
          await updateAuth({ ...authData, status: newUpdatedStatus });
          setAvailable(false);
        }
      })
      .catch(e => {
        console.log("catch", e);
      })
      .finally(() => {});
  };
  const pageTitle =
    pageTitles[pageKey] || pageTitleProps || pageTitles.lilyannlive;

  return (
    <>
      <Flex
        css={{
          width: "100%",
          height: 60,
          backgroundColor: "$background_dim",
          position: "fixed",
          zIndex: 1,
        }}
      >
        <Box
          css={{
            width: "100%",
            paddingTop: "$4",
            paddingRight: "$9",
            paddingBottom: "$4",
            paddingLeft: "$9",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Flex className="header-row">
            <Box>
              <Flex align="center" css={{ gridGap: "$8" }}>
                {!isMobile ? (
                  <>
                    <Link to="/" relative="path">
                      <Image src="/logo.svg" css={{ width: 200 }} />
                    </Link>
                    |
                  </>
                ) : (
                  <>
                    {location.pathname !== "/" ? (
                      <IconButton onClick={() => navigate("/")}>
                        <ChevronLeftIcon />
                      </IconButton>
                    ) : null}
                  </>
                )}
                <Text variant="body1">{pageTitle}</Text>
              </Flex>
            </Box>
            <Box>
              <Flex align="center" justify="end">
                <Dropdown.Root
                  open={available}
                  onOpenChange={setAvailable}
                  modal={true}
                >
                  <Dropdown.Trigger asChild>
                    <IconButton
                      variant="danger"
                      data-testid="leave_end_dropdown_trigger"
                      css={{
                        textTransform: "capitalize",
                        color:
                          authData?.status === "available"
                            ? "$alert_success"
                            : "$alert_warning",
                      }}
                    >
                      {statusIcons[authData?.status]}
                      {authData?.status}
                    </IconButton>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    css={{ p: 0 }}
                    alignOffset={-50}
                    sideOffset={10}
                  >
                    <Dropdown.Item
                      css={{
                        w: "100%",
                      }}
                      onClick={() => {
                        statusHandler("available");
                      }}
                    >
                      <Flex gap={4}>
                        {statusIcons.available}
                        <Flex direction="column" align="start">
                          <Text variant="lg">Available</Text>
                        </Flex>
                      </Flex>
                    </Dropdown.Item>
                    <Dropdown.Item
                      css={{
                        w: "100%",
                      }}
                      onClick={() => {
                        statusHandler("away");
                      }}
                    >
                      <Flex gap={4}>
                        {statusIcons.away}
                        <Flex direction="column" align="start">
                          <Text variant="lg">Away</Text>
                        </Flex>
                      </Flex>
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Root>
                <Dropdown.Root open={open} onOpenChange={setOpen} modal={false}>
                  <Dropdown.Trigger asChild>
                    <IconButton
                      variant="danger"
                      data-testid="leave_end_dropdown_trigger"
                    >
                      <VerticalMenuIcon />
                    </IconButton>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    css={{ p: 0 }}
                    alignOffset={-50}
                    sideOffset={10}
                  >
                    {authData?.is_admin === "1" && (
                      <Dropdown.Item
                        css={{
                          bg: "$surface_default",
                          "&:hover": { bg: "$surface_bright" },
                        }}
                        data-testid="just_leave_btn"
                        onClick={() => {
                          navigate("/assign");
                        }}
                      >
                        <Flex gap={4}>
                          <Flex direction="column" align="start">
                            <Text variant="lg">Assign Calls</Text>
                          </Flex>
                        </Flex>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      css={{
                        w: "100%",
                        bg: "rgba(178, 71, 81, 0.1)",
                        "&:hover": { backgroundColor: "$surface_bright" },
                      }}
                      onClick={() => {
                        logout();
                      }}
                      data-testid="logout_btn"
                    >
                      <Flex gap={4}>
                        <Flex direction="column" align="start">
                          <Text
                            variant="lg"
                            css={{ c: "$alert_error_default" }}
                          >
                            Logout{`(${authData?.name})`}
                          </Text>
                        </Flex>
                      </Flex>
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Root>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex
        className="header-placeholder"
        css={{
          width: "100%",
          height: 60,
          backgroundColor: "$background_dim",
        }}
      />
    </>
  );
};

export default LacHeader;
