import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import { EditIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, IconButton, Text } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";

const ListItem = ({ item, join = false, page = "", onItemSelect }) => {
  Moment.locale("en");
  const { setTourData, authData } = useAuth();
  const navigate = useNavigate();
  const joinedIds = item?.join_rejoin_ids?.split(",");

  const onClick = useCallback(() => {
    setTourData(item);
    const { room_id, host_token } = item;
    navigate(`/preview/${room_id}/salesrep?auth_token=${host_token}`);
  }, [item, navigate, setTourData]);

  const showJoin = () => {
    if (item?.status === "waiting") {
      return true;
    } else if (
      item?.status === "attended" &&
      (item?.salesrep_id === authData?.user_id ||
        joinedIds?.includes(authData?.user_id))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isRejoin =
    item?.status === "attended" &&
    (item?.salesrep_id === authData?.user_id ||
      joinedIds?.includes(authData?.user_id));
  const joinText = isRejoin ? "Rejoin" : "Join";

  let salesrep_name = item?.salesrep_name ? item.salesrep_name : "";
  let name = item.customer_name;

  if (page === "lilyannlive" && item.status !== "") {
    name +=
      " - " +
      item?.status?.slice(0, 1).toUpperCase() +
      item?.status?.slice(1, item?.status?.length);
    if (item?.status === "attended") {
      name += " by " + salesrep_name;
    }
  }

  if (item?.fullname) {
    name = item?.fullname;
  }

  return (
    <Flex css={{ width: "100%" }}>
      <Box
        css={{
          width: "100%",
          backgroundColor: "$on_primary_medium",
          padding: "$5 $8",
          borderRadius: "$2",
        }}
      >
        <Flex justify="between" align="center">
          <Box css={{ flex: 1 }}>
            <Text variant="body1">{name}</Text>

            {page === "lilyannlive" && (
              <Text variant="caption">
                {`${
                  item.status === "attended"
                    ? "Connected since "
                    : "Waiting since "
                }`}
                {item?.since}
              </Text>
            )}
            {page === "history" && (
              <Text variant="caption">
                Duration of call {item?.time_duration}
              </Text>
            )}
            {page === "schedule" && (
              <Text variant="caption">{item?.email}</Text>
            )}
            {page === "schedule" && (
              <Text variant="caption">{item?.support_type}</Text>
            )}
            {page === "schedule" && (
              <Text variant="caption">
                Call at{" "}
                {Moment(item?.appointment_datetime).format(
                  "MM/DD/yyyy hh:mm:ss A"
                )}
              </Text>
            )}
            {page === "missed" && (
              <Text variant="caption" css={{ color: "$alert_error_default" }}>
                {"Missed call at " +
                  Moment(item.created_at).format("MM/DD/yyyy hh:mm:ss A")}
              </Text>
            )}
          </Box>
          <Box>
            {join && showJoin() && (
              <Button onClick={onClick} css={{ padding: "$3 $8" }}>
                {joinText}
              </Button>
            )}
            {page === "history" && (
              <Flex direction="column" align="end">
                <Text variant="caption">
                  {Moment(item?.created_at).format("MM/DD/yyyy hh:mm:ss A")}
                </Text>
                <IconButton
                  css={{ marginBottom: -5 }}
                  onClick={() => onItemSelect(item)}
                >
                  <EditIcon />
                </IconButton>
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export const EmptyListItem = ({ emptyMessage }) => {
  return (
    <Flex css={{ width: "100%" }}>
      <Box
        css={{
          width: "100%",
          backgroundColor: "$on_primary_medium",
          padding: "$5 $8",
          borderRadius: "$2",
        }}
      >
        <Text align="center" variant="body1">
          {emptyMessage}
        </Text>
      </Box>
    </Flex>
  );
};

export default ListItem;
