/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useAuth } from "./Auth";
import { axiosInstance } from "../services/axiosService";

const HttpContext = createContext({
  post: () => {},
  getData: () => {},
  homePageRefreshed: 0,
  setHomePageRefreshed: () => {},
});

const HttpProvider = ({ children }) => {
  const { authData, updateAuth, signOut } = useAuth();
  const [homePageRefreshed, setHomePageRefreshed] = useState(0);

  const post = useCallback(
    query => {
      let config = authData?.token
        ? {
            headers: {
              Authorization: "bearer " + authData?.token,
            },
          }
        : {};
      return axiosInstance.post(process.env.REACT_APP_LAC_API, query, config);
    },
    [authData]
  );

  const getData = useCallback(
    res => {
      if (res.data.errors == null) {
        return res.data.data;
      } else {
        if (res.data.errors[0].code === 40001) {
          signOut();
        } else {
          console.error("Error", res.data.errors[0].message);
          toast.error(res.data.errors[0].message);
        }
      }
      return null;
    },
    [signOut]
  );

  const updateSalesRepStatus = useCallback(async () => {
    let body = {
      query: `mutation {
        SalesrepStatus(user_id: "${authData?.user_id}") {
          salesrep_status
          user_id
        }
      }`,
      variables: {},
    };
    const res = await post(body);
    const data = getData(res);
    if (data) {
      const { SalesrepStatus = {} } = data || {};
      const { salesrep_status } = SalesrepStatus;
      if (salesrep_status && authData?.status !== salesrep_status) {
        await updateAuth({ ...authData, status: salesrep_status });
      }
    }
  }, [authData]);

  useEffect(() => {
    if (authData) {
      updateSalesRepStatus();
    }
  }, [homePageRefreshed]);

  return (
    <HttpContext.Provider
      value={{ post, getData, homePageRefreshed, setHomePageRefreshed }}
    >
      {children}
    </HttpContext.Provider>
  );
};

function useHttp() {
  const context = useContext(HttpContext);

  if (!context) {
    throw new Error("useHttp must be used within an HttpProvider");
  }

  return context;
}

export { HttpContext, HttpProvider, useHttp };
