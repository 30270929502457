import { useCallback, useEffect, useState } from "react";
import { Flex, Loading, Text } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";
import { useHttp } from "../../../contexts/Http";
import ListItems from "../ListItems";

const TodaysSchedule = () => {
  const { authData, renderSchedule, setRenderSchedule } = useAuth();
  const { post, getData } = useHttp();
  const [vTourSchedules, setVTourSchedules] = useState([]);
  const [load, setLoad] = useState(false);

  const getScheduleCalls = useCallback(async () => {
    setLoad(true);
    const body = {
      query: `{
        VirtualTourScheduleList(virtualtourScheduleArgs: { salesrep_id: true }) {
          address
          appointment
          appointment_datetime
          current_time
          created_at
          email
          fullname
          is_reminder_email_sent
          phone
          room_id
          salesrep_id
          salesrep_name
          schedule_id
          support_type
          updated_at
          virtualtour_id
        }
      }`,
      variables: {},
    };

    const res = await post(body);
    const data = getData(res);
    if (data) {
      const { VirtualTourScheduleList } = data;
      const filteredData = VirtualTourScheduleList.filter(item => {
        const today = new Date(item?.current_time);
        today.setHours(23, 0, 0);
        const appDateTime = new Date(item?.appointment_datetime);
        if (appDateTime <= today) {
          return true;
        } else {
          return false;
        }
      });
      setVTourSchedules(filteredData);
    }
    setLoad(false);
  }, [post, getData]);

  useEffect(() => {
    if (renderSchedule) {
      getScheduleCalls();
      setRenderSchedule(0);
    }
  }, [renderSchedule, setRenderSchedule, getScheduleCalls]);

  useEffect(() => {
    if (authData) {
      getScheduleCalls();
    }
  }, [authData, getScheduleCalls]);

  if (load) {
    return (
      <Flex
        justify="center"
        align="center"
        css={{ width: "100%", minHeight: "40vh" }}
      >
        <Loading size={100} color="#333333" />
      </Flex>
    );
  }

  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
        <Text variant="body1">Today's Schedules</Text>
      </Flex>
      <ListItems
        items={vTourSchedules}
        emptyMessage="No calls in Schedule list."
        join={false}
        page="schedule"
      />
    </Flex>
  );
};
export default TodaysSchedule;
