/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { authService } from "../services/authService";

const AuthContext = createContext({
  authData: {},
  updateAuth: {},
  tourData: {},
  setTourData: () => {},
  loading: false,
  signIn: () => {},
  signOut: () => {},
  renderVirtualTour: 0,
  setRenderVirtualTour: () => {},
  renderSchedule: 0,
  setRenderSchedule: () => {},
});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useState();
  const [tourData, setTourData] = useState();
  const [loading, setLoading] = useState(true);
  const [renderVirtualTour, setRenderVirtualTour] = useState(0);
  const [renderSchedule, setRenderSchedule] = useState(0);

  const loadStorageData = () => {
    try {
      const authDataSerialized = localStorage.getItem("@AuthData");
      if (authDataSerialized) {
        const _authData = JSON.parse(authDataSerialized);
        setAuthData(_authData);
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tourData !== undefined) {
      localStorage.setItem("@tourData", JSON.stringify(tourData));
    } else if (localStorage.getItem("@tourData")) {
      const localTourData = JSON.parse(localStorage.getItem("@tourData"));
      if (localTourData) {
        setTourData(localTourData);
      }
    }
  }, [tourData]);

  useEffect(() => {
    loadStorageData();
  }, []);

  const signIn = useCallback(async object => {
    const _authData = await authService.signIn(object);
    setAuthData(_authData);
    localStorage.setItem("@AuthData", JSON.stringify(_authData));
    navigate("/");
  }, []);

  const signOut = useCallback(() => {
    setAuthData(undefined);
    localStorage.removeItem("@AuthData");
    navigate("/login");
  }, []);

  const updateAuth = async auth_data => {
    localStorage.setItem("@AuthData", JSON.stringify(auth_data));
    setAuthData(auth_data);
  };

  return (
    <AuthContext.Provider
      value={{
        authData,
        tourData,
        setTourData,
        updateAuth,
        loading,
        signIn,
        signOut,
        renderVirtualTour,
        setRenderVirtualTour,
        renderSchedule,
        setRenderSchedule,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
