import { useCallback, useEffect, useState } from "react";
import { Flex } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";
import { useHttp } from "../../../contexts/Http";
import LacLoading from "../LacLoading";
import ListItems from "../ListItems";

const Missed = () => {
  const { authData } = useAuth();
  const { post, getData } = useHttp();
  const [missed, setMissed] = useState([]);
  const [load, setLoad] = useState(false);
  const [page] = useState(1);

  const getMissedCalls = useCallback(
    async (loadMore = false) => {
      let pageNo = loadMore ? page : 1;
      if (!loadMore) {
        setMissed([]);
        setLoad(true);
      }

      const body = {
        query: `
        {
          VirtualTourLogList(
            virtualtourLogArgs: { is_missed: true, salesrep_id: ${
              authData?.is_admin === "1" ? "false" : "true"
            } }
            pageSize: 10
            currentPage: ${pageNo}
          ) {
            total_count
            total_pages
            items {
              address
              created_at
              customer_email
              customer_name
              is_missed
              missed_by_salesrep
              missed_by_salesrep_name
              notes
              room_id
              salesrep_id
              salesrep_name
              telephone
              time_duration
              updated_at
              virtualtour_id
              virtualtourlogs_id
            }
          }
        }`,
        variables: {},
      };

      const res = await post(body);
      const data = getData(res);
      if (data) {
        const {
          VirtualTourLogList: { items = [] },
        } = data;
        setMissed(items);
      }
      setLoad(false);
    },
    [post, page, authData, getData]
  );

  useEffect(() => {
    if (authData) {
      getMissedCalls();
    }
  }, [authData, getMissedCalls]);

  if (load) {
    return <LacLoading />;
  }
  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      <ListItems
        items={missed}
        emptyMessage="No missed calls in history."
        join={false}
        page="missed"
      />
    </Flex>
  );
};
export default Missed;
