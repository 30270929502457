import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Dialog, Flex, Label, RadioGroup } from "@100mslive/roomkit-react";
import { useHttp } from "../../../contexts/Http";
import { DialogContent } from "../../../primitives/DialogContent";

export const SalesRepListModal = ({
  salesReps = [],
  selectedCall = {},
  handleSelected,
}) => {
  const { schedule_id, salesrep_id = "" } = selectedCall;
  const { post, getData } = useHttp();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(salesrep_id);
  }, [salesrep_id]);

  const handleChange = newValue => {
    setValue(newValue);
    assignSalesRep(newValue);
  };

  const assignSalesRep = user_id => {
    let body = {
      query: `mutation {
         AssignSalesrepToSchedule(schedule_id: "${schedule_id}", user_id: "${user_id}") {
          address
          appointment
          appointment_datetime
          created_at
          current_time
          email
          fullname
          is_reminder_email_sent
          phone
          room_id
          salesrep_id
          salesrep_name
          schedule_id
          support_type
          updated_at
          virtualtour_id
        }
      }`,
      variables: {},
    };

    post(body)
      .then(res => {
        const data = getData(res);
        if (data) {
          handleSelected({}, true);
          toast.success("You have assigned Sales Rep Successfully.");
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {});
  };

  return (
    <Dialog.Root
      open={JSON.stringify(selectedCall) !== "{}"}
      modal={false}
      onOpenChange={() => handleSelected({})}
    >
      <DialogContent
        className="sales-rep-dialog"
        closeable={true}
        title="Select sales rep"
      >
        <Flex
          align="center"
          justify="start"
          direction="column"
          css={{ pt: "$8", width: "fit-content" }}
        >
          <RadioGroup.Root
            value={value}
            onValueChange={handleChange}
            css={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            {salesReps.map((item, i) => (
              <Flex
                align="center"
                justify="start"
                css={{ mr: "$8", mb: "$5" }}
                key={i}
              >
                <RadioGroup.Item
                  value={item.user_id}
                  id={item.user_id}
                  css={{
                    mr: "$3",
                    cursor: "pointer",
                    '&[data-state="checked"]': {
                      borderColor: "$primary_bright",
                      borderWidth: "5px",
                    },
                  }}
                >
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
                <Label
                  htmlFor={item.user_id}
                  css={{ color: "$on_surface_high", cursor: "pointer" }}
                >
                  {item?.name}
                </Label>
              </Flex>
            ))}
          </RadioGroup.Root>
        </Flex>
      </DialogContent>
    </Dialog.Root>
  );
};
