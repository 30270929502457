import { Flex, Loading } from "@100mslive/roomkit-react";

const LacLoading = () => {
  return (
    <Flex
      justify="center"
      align="center"
      css={{ width: "100%", height: "80vh" }}
    >
      <Loading size={100} color="#333333" />
    </Flex>
  );
};

export default LacLoading;
