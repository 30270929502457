import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import { useAuth } from "../../contexts/Auth";

const LacTabs = ({ tabs }) => {
  const {
    renderVirtualTour,
    setRenderVirtualTour,
    renderSchedule,
    setRenderSchedule,
  } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (renderVirtualTour) {
      setSearchParams({ tab: "lilyannlive" });
    } else if (renderSchedule) {
      setSearchParams({ tab: "schedule" });
    }
  }, [renderVirtualTour, renderSchedule, setSearchParams]);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      const index = tabs.findIndex(({ key }) => key === tab);
      setActiveTab(index);
    } else {
      setActiveTab(0);
    }
  }, [searchParams, tabs]);

  const handleTabClick = key => {
    const tab = searchParams.get("tab");
    setSearchParams({ tab: key });
    if (tab === key) {
      if (key === "lilyannlive") {
        setRenderVirtualTour(i => i + 1);
      } else if (key === "schedule") {
        setRenderSchedule(i => i + 1);
      }
    }
  };

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      <Flex
        className="lac-tab-content"
        css={{ width: "100%", minHeight: "80vh", paddingBottom: "100px" }}
      >
        {tabs[activeTab].content}
      </Flex>
      <Box className="lac-tab-list-container">
        <Flex
          className="lac-tab-list"
          css={{ backgroundColor: "$background_dim" }}
        >
          {tabs.map((tab, index) => (
            <Box
              key={index}
              className={`lac-tab ${index === activeTab ? "active" : ""}`}
              onClick={() => handleTabClick(tab.key)}
              css={{ cursor: "pointer", flex: 1 }}
            >
              <Flex align="center" justify="center" direction="column">
                <Box>{tab.icon}</Box>
                <Text css={{ color: "inherit" }} variant="xs">
                  {tab.label}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};

export default LacTabs;
