import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { isSupported } from "firebase/messaging";
import {
  Box,
  Button,
  Flex,
  Input,
  PasswordInput,
  Text,
} from "@100mslive/roomkit-react";
import { Image } from "../../components/Image";
import MainContainer from "../../components/LacCommon/MainContainer";
import { useAuth } from "../../contexts/Auth";
import { useHttp } from "../../contexts/Http";
import { getFCMToken } from "../../services/firebaseService";

const Login = () => {
  const [notificationEnabled, setNotificationEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();
  const { post, getData } = useHttp();

  useEffect(() => {
    (async () => {
      if (typeof window !== "undefined") {
        const fcmToken = await getFCMToken();
        if (!fcmToken) {
          setNotificationEnabled(false);
          toast.error("Error occured while fetching Firebase Token!", {
            duration: 60000,
          });
        }
        const hasSupported = await isSupported();
        if (!hasSupported) {
          setNotificationEnabled(false);
          toast.error("Your browser does not support web push notification!", {
            duration: 60000,
          });
        } else {
          Notification.requestPermission().then(async permission => {
            if (permission !== "granted") {
              setNotificationEnabled(false);
              toast.error("You have not allowed notification!", {
                duration: 60000,
              });
            }
          });
        }
      }
    })();
  }, []);

  const callLoginAPI = async () => {
    const fcmToken = await getFCMToken();
    console.warn("fcmToken", fcmToken);
    if (fcmToken) {
      let body = {
        query: `mutation { generateSalesrepToken(username:"${username}", password: "${password}", device_id: "${fcmToken}") {
            created_at
            email
            name
            token
            user_id
            is_admin
            status
          }}`,
        variables: {},
      };
      post(body)
        .then(async res => {
          if (res.data.errors == null) {
            const data = getData(res);
            if (data) {
              auth.signIn(data.generateSalesrepToken);
            }
          } else {
            toast.error(res.data.errors[0].message);
          }
        })
        .catch(async error => {
          if (error.toJSON().message === "Network Error") {
            toast.error("no internet connection");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (username && password) {
      setLoading(true);
      callLoginAPI();
    }
  };

  return (
    <MainContainer header={false}>
      <Flex
        justify="center"
        align="center"
        style={{ height: "100vh", width: "100%" }}
        direction="column"
      >
        <Flex css={{ marginBottom: "$8" }} direction="column">
          <Text variant="h5" align="center">
            Login
          </Text>
          <Text variant="sub1">Welcome to Lily Ann Cabinets</Text>
        </Flex>
        <Box
          css={{
            border: "1px solid #ddd",
            padding: "$10",
            backgroundColor: "$background_dim",
            width: 320,
          }}
        >
          <Flex justify="center" align="center" css={{ marginBottom: "$8" }}>
            <Image src="/logo.svg" style={{ width: 250 }} />
          </Flex>
          <form onSubmit={handleSubmit}>
            <Flex direction="column">
              <Box css={{ paddingBottom: "$8" }}>
                <Input
                  required
                  placeholder="Username"
                  value={username}
                  onChange={e => setUsername(e.target?.value)}
                  css={{ width: "100%" }}
                />
              </Box>
              <Box css={{ paddingBottom: "$8" }}>
                <PasswordInput.Root>
                  <PasswordInput.Input
                    required
                    placeholder="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={e => setPassword(e.target?.value)}
                    css={{ width: "100%" }}
                  />
                  <PasswordInput.Icons>
                    <PasswordInput.ShowIcon
                      showPassword={showPassword}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    />
                  </PasswordInput.Icons>
                </PasswordInput.Root>
              </Box>

              <Button
                loading={loading}
                type="submit"
                disabled={!notificationEnabled}
              >
                Login
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </MainContainer>
  );
};

export default Login;
