/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Moment from "moment";
import { ChevronDownIcon } from "@100mslive/react-icons";
import { Box, Flex, IconButton, Text } from "@100mslive/roomkit-react";
import LacLoading from "../../components/LacCommon/LacLoading";
import { EmptyListItem } from "../../components/LacCommon/ListItems/ListItem";
import MainContainer from "../../components/LacCommon/MainContainer";
import { SalesRepListModal } from "../../components/LacCommon/Modals/SalesRepModal";
import { useAuth } from "../../contexts/Auth";
import { useHttp } from "../../contexts/Http";

const Assign = () => {
  const navigate = useNavigate();
  Moment.locale("en");
  const [searchParams] = useSearchParams();
  const { authData } = useAuth();
  const { post, getData } = useHttp();
  const [salesReps, setSalesReps] = useState([]);
  const [collsData, setCallsData] = useState([]);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCall, setSelectedCall] = useState({});

  const unassigned = searchParams.get("unassigned");
  const unAssignedOnly = unassigned === "true" ? true : false;

  useEffect(() => {
    if (authData && authData?.is_admin !== "1") {
      navigate("/");
    }
  }, [authData]);

  const getUnAssignedCalls = async (loadMore = false) => {
    let pageNo = page;
    if (!loadMore) {
      setLoad(true);
      pageNo = 1;
    }

    let body = {
      query: `
      {
        VirtualTourScheduleListSearch(
          virtualtourScheduleArgs: { salesrep_id: false }
          pageSize: ${unAssignedOnly ? 100 : 10}
          currentPage: ${pageNo}
        ) {
          total_count
          total_pages
          items {
            address
            appointment
            appointment_datetime
            current_time
            created_at
            email
            fullname
            is_reminder_email_sent
            phone
            room_id
            salesrep_id
            salesrep_name
            schedule_id
            support_type
            updated_at
            virtualtour_id
          }
        }
      }`,
      variables: {},
    };
    post(body)
      .then(res => {
        const { VirtualTourScheduleListSearch = {} } = getData(res) || {};
        let { items = [], total_pages } = VirtualTourScheduleListSearch;
        items = items.filter(
          i => new Date(i.appointment_datetime) >= new Date(i.current_time)
        );
        if (unAssignedOnly) {
          items = items.filter(i => i.salesrep_id === "0");
        }
        if (total_pages) {
          setTotalPages(total_pages);
        }
        setPage(count => count + 1);
        if (items?.length) {
          if (!loadMore) {
            setCallsData([...items]);
          } else {
            const newTotal = collsData.concat(items);
            setCallsData([...newTotal]);
          }
        }
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const salesRepList = () => {
    const body = {
      query: `
      {
        VirtualTourSalesrepList {
          user_id
          email
          name
          created_at
          is_admin
        }
      }`,
      variables: {},
    };
    post(body)
      .then(res => {
        const data = getData(res) || {};
        const { VirtualTourSalesrepList = [] } = data;
        setSalesReps(VirtualTourSalesrepList);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (authData) {
      getUnAssignedCalls();
      salesRepList();
    }
  }, [authData]);

  const handleSelect = useCallback(
    (value, refresh) => {
      if (authData) {
        if (refresh) getUnAssignedCalls();
        setSelectedCall(value);
      }
    },
    [authData]
  );

  return (
    <>
      <MainContainer header={true} pageTitle="Assign Schedule Calls">
        <Flex
          css={{
            width: "100%",
            maxWidth: 1320,
            padding: 20,
            backgroundColor: "$background_dim",
            minHeight: "calc(100vh - 60px)",
          }}
          justify="center"
        >
          {load ? (
            <LacLoading />
          ) : (
            <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
              {collsData.length > 0 ? (
                collsData.map((item, index) => (
                  <Flex css={{ width: "100%" }} key={index}>
                    <Box
                      css={{
                        width: "100%",
                        backgroundColor: "$on_primary_medium",
                        padding: "$5 $8",
                        borderRadius: "$2",
                      }}
                    >
                      <Flex justify="between" align="center">
                        <Box css={{ flex: 1 }}>
                          <Text variant="body1">
                            {item?.customer_name || item?.fullname}
                          </Text>
                          <Text variant="caption">{item?.email}</Text>
                          <Text variant="caption">{item?.support_type}</Text>
                          <Text variant="caption">
                            Call at{" "}
                            {Moment(item?.appointment_datetime).format(
                              "MM/DD/yyyy hh:mm:ss A"
                            )}
                          </Text>
                        </Box>
                        <Box css={{ backgroundColor: "$on_primary_high" }}>
                          <IconButton
                            onClick={() => {
                              setSelectedCall(item);
                            }}
                          >
                            {item?.salesrep_name
                              ? item.salesrep_name
                              : "Assign"}{" "}
                            <ChevronDownIcon />{" "}
                          </IconButton>
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                ))
              ) : (
                <EmptyListItem emptyMessage="No schedule calls in history." />
              )}
            </Flex>
          )}
        </Flex>
      </MainContainer>
      <SalesRepListModal
        salesReps={salesReps}
        selectedCall={selectedCall}
        handleSelected={handleSelect}
      />
    </>
  );
};

export default Assign;
