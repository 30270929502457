import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CheckIcon } from "@100mslive/react-icons";
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Input,
  Label,
  styled,
} from "@100mslive/roomkit-react";
import { useHttp } from "../../../contexts/Http";
import { DialogContent, DialogRow } from "../../../primitives/DialogContent";
import "../LacCommon.css";

const escape = str => {
  return str
    .replace(/[\\]/g, "\\\\")
    .replace(/[\"]/g, '\\"')
    .replace(/[\/]/g, "\\/")
    .replace(/[\b]/g, "\\b")
    .replace(/[\f]/g, "\\f")
    .replace(/[\n]/g, "\\n")
    .replace(/[\r]/g, "\\r")
    .replace(/[\t]/g, "\\t");
};

export const CustomerFormModal = ({
  selectedCall = {},
  handleSelected,
  callHistory = false,
}) => {
  const { post, getData } = useHttp();
  const [formData, setFormData] = useState();
  const [top, setTop] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTop(window.scrollY + 400 || 0);
  }, [selectedCall]);

  useEffect(() => {
    const {
      customer_name,
      name,
      customer_email,
      email,
      telephone,
      phone,
      address,
      notes,
      is_free_sample,
      is_3d_design,
    } = selectedCall;

    setFormData({
      name: customer_name || name || "",
      email: customer_email || email || "",
      phone: telephone || phone || "",
      address: address || "",
      notes: notes || "",
      is_free_sample: is_free_sample && is_free_sample !== "0" ? true : false,
      is_3d_design: is_3d_design && is_3d_design !== "0" ? true : false,
    });
  }, [selectedCall]);

  const handleChange = (key, value) => {
    const newData = {
      ...formData,
      [key]: value,
    };
    setFormData(newData);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { virtualtourlogs_id, virtualtour_id } = selectedCall;
    if (
      !formData?.name ||
      !formData?.email ||
      !formData?.phone ||
      !formData?.address
    ) {
      toast.error("Required customer data not filled yet.");
    }

    setLoading(true);
    let body = {};
    if (callHistory) {
      body = {
        query: `
            mutation {
              UpdateVirtualTourLog(
                virtualtourlogs_id: "${virtualtourlogs_id}",
                virtualtourData: {
                  customer_email: "${formData.email}",
                  telephone: "${formData.phone}",
                  address: "${escape(formData.address)}",
                  notes: "${escape(formData.notes)}",
                  customer_name: "${formData.name}",
                  is_3d_design: ${formData.is_3d_design ? "true" : "false"},
                  is_free_sample: ${formData.is_free_sample ? "true" : "false"}
                }
              ) {
                address
                created_at
                customer_email
                customer_name
                did_salesrep
                exp_vtour_rating
                feedback_comments
                is_missed
                missed_by_salesrep
                missed_by_salesrep_name
                notes
                room_id
                salesrep_id
                salesrep_name
                satisfaction_rating
                schedule_id
                support_type
                telephone
                time_duration
                updated_at
                virtualtour_id
                virtualtourlogs_id
              }
            }
          `,
        variables: {},
      };
    } else {
      body = {
        query: `
        mutation {
          UpdateVirtualTour(
            virtualtour_id: "${virtualtour_id}", 
            virtualtourData: {
              customer_email:"${formData.email}",
              telephone:"${formData.phone}",
              address:"${escape(formData.address)}",
              notes:"${escape(formData.notes)}",
              customer_name:"${formData.name}",
              is_3d_design: ${formData.is_3d_design ? "true" : "false"},
              is_free_sample: ${formData.is_free_sample ? "true" : "false"}
             }
            )  {
            address
            created_at
            customer_email
            customer_name
            disconnected_on
            duration
            email
            guest_token
            host_token
            joined_on
            notes
            recorded_session_link
            room_id
            salesrep_id
            salesrep_name
            since
            status
            telephone
            updated_at
            virtualtour_id
          }
        }
          `,
        variables: {},
      };
    }

    post(body)
      .then(res => {
        const data = getData(res);
        if (data) {
          if (callHistory) {
            handleSelected({}, true);
          } else {
            handleSelected(formData, true);
          }
          toast.success("Customer data saved successfully.");
        }
      })
      .catch(e => {
        console.log(e);
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog.Root
      open={JSON.stringify(selectedCall) !== "{}"}
      modal={false}
      onOpenChange={() => handleSelected({})}
    >
      <DialogContent
        className="customer-form-dialog"
        closeable={true}
        title="Customer Form"
        css={{
          backgroundColor: "$background_dim",
          top,
        }}
      >
        <Form
          onSubmit={e => handleSubmit(e)}
          css={{ flexDirection: "column", mb: 0 }}
        >
          <Flex
            align="center"
            justify="start"
            direction="column"
            css={{ width: "100%" }}
          >
            <Flex
              direction="column"
              css={{ pb: "$5", width: "100%", gridGap: "$5" }}
            >
              <Label
                htmlFor="fullname"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
                className="required"
              >
                Full name
              </Label>

              <Input
                required
                type="text"
                id="fullname"
                css={{ w: "100%" }}
                value={formData?.name}
                onChange={e => handleChange("name", e.target.value.trimStart())}
                placeholder="Enter your name"
              />
            </Flex>
            <Flex
              direction="column"
              css={{ pb: "$5", width: "100%", gridGap: "$5" }}
            >
              <Label
                htmlFor="email"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
                className="required"
              >
                Email
              </Label>

              <Input
                required
                type="email"
                id="email"
                css={{ w: "100%" }}
                value={formData?.email}
                onChange={e =>
                  handleChange("email", e.target.value.trimStart())
                }
                placeholder="Enter Email"
              />
            </Flex>
            <Flex
              direction="column"
              css={{ pb: "$5", width: "100%", gridGap: "$5" }}
            >
              <Label
                htmlFor="phone"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
                className="required"
              >
                Phone
              </Label>

              <Input
                required
                id="phone"
                type="tel"
                css={{ w: "100%" }}
                value={formData?.phone}
                onChange={e =>
                  handleChange("phone", e.target.value.trimStart())
                }
                placeholder="Enter Phone"
              />
            </Flex>
            <Flex
              direction="column"
              css={{ pb: "$5", width: "100%", gridGap: "$5" }}
            >
              <Label
                htmlFor="address"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
                className="required"
              >
                Address
              </Label>
              <TextArea
                required
                rows="4"
                id="address"
                css={{ w: "100%" }}
                value={formData?.address}
                onChange={e =>
                  handleChange("address", e.target.value.trimStart())
                }
                placeholder="Enter Address"
              />
            </Flex>
            <Flex
              direction="column"
              css={{ pb: "$5", width: "100%", gridGap: "$5" }}
            >
              <Label
                htmlFor="notes"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
              >
                Notes
              </Label>
              <TextArea
                rows="4"
                id="notes"
                css={{ w: "100%" }}
                value={formData?.notes}
                onChange={e =>
                  handleChange("notes", e.target.value.trimStart())
                }
                placeholder="Enter Notes"
              />
            </Flex>
            <Flex
              css={{
                pb: "$5",
                width: "100%",
                gridGap: "$5",
                alignItems: "center",
              }}
            >
              <Checkbox.Root
                id="is_free_sample"
                css={{ margin: "$2" }}
                checked={formData?.is_free_sample}
                onCheckedChange={value => handleChange("is_free_sample", value)}
              >
                <Checkbox.Indicator>
                  <CheckIcon width={16} height={16} />
                </Checkbox.Indicator>
              </Checkbox.Root>

              <Label
                htmlFor="is_free_sample"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
              >
                Free Samples
              </Label>
            </Flex>
            <Flex
              css={{
                pb: "$5",
                width: "100%",
                gridGap: "$5",
                alignItems: "center",
              }}
            >
              <Checkbox.Root
                id="is_3d_design"
                css={{ margin: "$2" }}
                checked={formData?.is_3d_design}
                onCheckedChange={value => handleChange("is_3d_design", value)}
              >
                <Checkbox.Indicator>
                  <CheckIcon width={16} height={16} />
                </Checkbox.Indicator>
              </Checkbox.Root>

              <Label
                htmlFor="is_3d_design"
                css={{ color: "$on_surface_high", cursor: "pointer" }}
              >
                3D Design
              </Label>
            </Flex>
          </Flex>
          <DialogRow justify="end" css={{ margin: 0, gridGap: "$10" }}>
            <Button
              type="button"
              variant="standard"
              onClick={() => {
                handleSelected({});
              }}
            >
              Close
            </Button>
            <Button loading={loading} type="submit" variant="primary">
              Save
            </Button>
          </DialogRow>
        </Form>
      </DialogContent>
    </Dialog.Root>
  );
};

const TextArea = styled("textarea", {
  fontFamily: "$sans",
  lineHeight: "inherit",
  backgroundColor: "$surface_default",
  borderRadius: "8px",
  outline: "none",
  border: "1px solid $border_default",
  padding: "0.5rem 0.75rem",
  minHeight: "30px",
  color: "$on_surface_high",
  fontSize: "$md",
  "&:focus": {
    boxShadow: "0 0 0 1px $colors$primary_default",
    border: "1px solid transparent",
  },
  "&::placeholder": {
    color: "$on_surface_medium",
  },
  variants: {
    alert_error_default: {
      true: {
        "&:focus": {
          boxShadow: "0 0 0 3px $colors$alert_error_default",
        },
      },
    },
  },
});

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});
