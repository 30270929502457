import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LAC_API,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
    "Platform-Type": "browser",
  },
});
