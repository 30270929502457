import { Flex } from "@100mslive/roomkit-react";
import ListItem, { EmptyListItem } from "./ListItem";

const ListItems = ({ items, emptyMessage = "", ...rest }) => {
  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      {items.length > 0 ? (
        items.map((item, index) => (
          <ListItem item={item} key={index} {...rest} />
        ))
      ) : (
        <EmptyListItem emptyMessage={emptyMessage} />
      )}
    </Flex>
  );
};

export default ListItems;
