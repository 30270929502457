/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Flex } from "@100mslive/roomkit-react";
import { useAuth } from "../../../contexts/Auth";
import { useHttp } from "../../../contexts/Http";
import LacLoading from "../LacLoading";
import ListItems from "../ListItems";
import { CustomerFormModal } from "../Modals/CustomerFormModal";

const History = () => {
  const { authData } = useAuth();
  const { post, getData } = useHttp();
  const [history, setHistory] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedCall, setSelected] = useState({});

  const getCallsHistory = async (loadMore = false) => {
    let pageNo = loadMore ? page : 1;
    if (!loadMore) {
      setHistory([]);
      setLoad(true);
    } else {
      setLoading(true);
    }

    const body = {
      query: `{
          VirtualTourLogList(
            virtualtourLogArgs: { is_missed: false, salesrep_id: true }
            pageSize: 20
            currentPage: ${pageNo}
          ) {
            total_count
            total_pages
            items {
              address
              created_at
              customer_email
              customer_name
              is_missed
              missed_by_salesrep
              missed_by_salesrep_name
              notes
              room_id
              salesrep_id
              salesrep_name
              telephone
              did_salesrep
              satisfaction_rating
              exp_vtour_rating
              feedback_comments
              time_duration
              updated_at
              virtualtour_id
              virtualtourlogs_id
              is_free_sample
              is_3d_design
            }
          }
        }`,
      variables: {},
    };

    const res = await post(body);
    const data = getData(res);
    if (data) {
      const {
        VirtualTourLogList: { items = [] },
      } = data;
      if (loadMore) {
        setHistory([...history, ...items]);
      } else {
        setHistory(items);
      }

      setPage(i => i + 1);
    }
    setLoad(false);
    if (loadMore) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authData) {
      getCallsHistory();
    }
  }, [authData]);

  const handleSelected = (value, refresh = false) => {
    setSelected(value);
    if (refresh) {
      getCallsHistory();
    }
  };

  const handleLoadMore = () => {
    getCallsHistory(true);
  };

  if (load) {
    return <LacLoading />;
  }
  return (
    <Flex css={{ width: "100%", gridGap: "$8" }} direction="column">
      <ListItems
        items={history}
        emptyMessage="No call history found."
        join={false}
        page="history"
        onItemSelect={setSelected}
      />
      {history?.length >= 20 ? (
        <Flex justify="center">
          <Button loading={loading} onClick={() => handleLoadMore()}>
            Load More
          </Button>
        </Flex>
      ) : null}
      <CustomerFormModal
        {...{ selectedCall, handleSelected, callHistory: true }}
      />
    </Flex>
  );
};
export default History;
